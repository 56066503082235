$(document).ready(function(){

  $('.m-menu').click(function(){
    $('html, body').toggleClass('noscroll');
    $('body').toggleClass('m-menu-open');
  });

  // scrolling stuff

  var scrolled = 0;
  function handleScroll () {
    scrolled = window.scrollY;
    var scrollOffsetFirst = 200;
    var scrollOffset = 300;
    if(scrolled > scrollOffsetFirst){
      document.querySelector('body').classList.add('prescrolling');
    }else{
      document.querySelector('body').classList.remove('prescrolling');
    }
    if(scrolled > scrollOffset){
      document.querySelector('body').classList.add('scrolling');
    }else{
      document.querySelector('body').classList.remove('scrolling');
    }
  }

  if(!$('body').hasClass('home')){
    window.addEventListener('scroll', handleScroll);
  }


});